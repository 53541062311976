.notification_container {
  .triple_row {
    & > * {
      display: inline-block;
      width: 25%;

      &:first-child {
        width: 50%;
      }

      &:not(:first-child) {
        text-align: center;
      }
    }

    &_inner {
      & > * {
        &:first-child {
          margin-left: 2.75rem;
          width: calc(50% - 2.75rem);
        }
      }
    }
  }
}
