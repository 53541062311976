@import '../../styles/variables.scss';
@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

.content {
  border: 2px solid rgba($text-color, 0.15);

  @include mid-fluid-value(padding-top, 1.5rem, 2rem);
  @include mid-fluid-value(padding-right, 3rem, 4rem);
  @include mid-fluid-value(padding-bottom, 1.5rem, 2rem);
  @include mid-fluid-value(padding-left, 1rem, 1.75rem);

  div {
    & > span,
    & > span:not([class]) {
      display: block;
      color: $text-color;
      @include mid-fluid-value(margin-bottom, 0.15rem, 0.45rem);
    }
  }

  // Dodany 'div' przed 'svg', żeby zasada była bardziej 'doprecyzowana' niż zasada z AuctionPage.scss
  div svg {
    color: $accent;
    align-self: flex-start;
  }
}

.header__label {
  color: $accent;
  font-weight: 500;
  @include quickTransition();
  @include mid-fluid-value(font-size, 1.25rem, 1.5rem);

  &--small {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    @include mid-fluid-value(margin-top, 0.15rem, 0.5rem);
  }

  &--xsmall {
    font-size: $font-size-xxsmall;
  }
}

.badge {
  background-color: $series-d;
  padding: 0.2rem 1rem;
  border-radius: 20px;
}
