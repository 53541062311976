@import '../styles/theme-variables.scss';
@import '../styles/mixins.scss';

$row-items-offset: 0.75rem;

.box {
  padding: 1rem 0.25rem;
  width: calc((100% - #{$row-items-offset * 2}) / 3) !important;
  text-align: center;
  border-radius: 0.25rem;
  box-sizing: border-box;
  word-wrap: break-word;
  user-select: none;
  @include box-shadow(0, 3px, 11px, -3px);

  &:nth-of-type(n + 4) {
    margin-top: $row-items-offset;
  }

  &,
  & > div {
    @include transition();
  }

  // Nie leci tutaj :last-of-type, w razie jakby przyszedł koncept, gdzie potrzebna bylaby większa ilość pudełeczek
  &:not(:nth-of-type(3)) {
    margin-right: $row-items-offset;
  }

  &_quantity {
    margin-bottom: 0.25rem;
    font-weight: 700;
    font-size: 1.375rem;
    color: $accent;
  }

  &_label {
    font-weight: 600;
    font-size: 0.8125rem;
    color: $grey-text;
    word-break: break-word;
    text-transform: lowercase;
  }

  &:hover {
    background-color: $accent;
    cursor: pointer;

    .box_quantity,
    .box_label {
      color: $selected-text;
    }
  }
}
