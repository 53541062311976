@import '../../styles/theme-variables.scss';

.notification_row {
  position: relative;
  margin-top: 0.75rem;
}

.notification_name {
  font-weight: 500;
  color: $text-color;
}
