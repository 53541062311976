@import '../styles/theme-variables.scss';

.login_page_container {
  display: flex;
  margin: 0 auto;
  width: 65%;
  flex-direction: column;
  align-items: stretch;

  img {
    width: 160px;
    margin-bottom: 1.5rem;
    user-select: none;
    pointer-events: none;
  }

  & > div:not(:first-of-type) {
    margin-bottom: 0.5rem;

    &:nth-of-type(3) {
      margin-bottom: 1.5rem;
    }
  }

  .bold-medium {
    font-size: 34px;
  }
}
