@import 'styles/theme-variables.scss';

// W przypadku pyknięcia powiadomień w reduxformie, można wywalić te style xD.
// Można to zrobić dlatego, że redux form fieldy mają defaultowe stylowanie w 'styles/theming.scss', przez co nie jest potrzebne robieni customowego stylowanka :P

.notification-container {
  .notification-area {
    &:not(:last-of-type) {
      margin-bottom: 0.75rem;
    }

    &:focus-within {
      .notification-label {
        color: $accent;
      }
    }
  }

  .notification-label {
    display: block;
    padding-bottom: 0.25rem;
    font-weight: 500;
    font-size: 0.75rem;
    color: rgba($text-color, 0.85);
  }
}
