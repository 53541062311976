@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

$emote-size: 1.65em;
$icon-color: var(--badge-background-color);

.badge {
  margin-top: 0.3rem;
  // Style z '!important' nadpisują style z Badge.module.scss
  padding-right: 0.25rem !important;
  font-size: 0.685rem !important;
  align-self: flex-end;
  @include flex(row, flex-start, center);

  & > span {
    font-weight: 600;
  }

  &.align-center {
    align-self: center;
  }
}

.icon {
  $emote-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

  position: relative;
  display: inline-block;
  margin-left: 0.25rem;
  font-size: $emote-size;
  color: $icon-color;
  opacity: 0;
  box-sizing: border-box;
  animation: emoteAppearing 350ms 700ms forwards;
}

@keyframes emoteAppearing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
