@import '../../styles/theme-variables.scss';

.dashboard_text {
  &_container {
    font-size: 1.5rem;
    margin-bottom: 1.75em;
  }

  &_title,
  &_information {
    font-size: 1em;
    font-weight: 900;
    text-transform: initial;
    padding-bottom: 0.5em;
  }

  &_title {
    margin-right: 0.5em;
  }

  &_information {
    color: $error;
  }

  &_description {
    font-size: 0.75em;
    margin: 0;
  }
}
