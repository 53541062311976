@import '../../styles/theme-variables.scss';

.price-section-container {
  .k-textbox-container {
    position: relative;

    .k-widget.k-numerictextbox {
      .k-numeric-wrap {
        input {
          font-size: 1.1rem;
        }
      }
    }

    .k-label {
      top: -0.25rem;
      left: 0;
      font-size: 0.8rem;
      transform: none !important;
    }
  }
}
