@import 'hh-shared/dist/styles/theme-variables.scss';
@import '@progress/kendo-theme-default/scss/all.scss';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,800&display=swap');
@import './variables.scss';
@import 'hh-shared/dist/styles/animations.scss';
@import 'hh-shared/dist/styles/mixins.scss';
@import 'hh-shared/dist/styles/utility-classes.scss';
@import 'hh-shared/dist/styles/shared-styles.scss';
@import 'hh-shared/dist/styles/theming.scss';
@import 'fox-react/dist/styles/theming.scss';
