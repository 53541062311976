@import '../styles/theme-variables.scss';
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

$default-icon-size: 4rem;
$card-offset: 1.625rem;
$card-item-offset: 1.75rem;

.card_container {
  width: 100%;
  min-width: 400px;
  height: fit-content;
  padding: $card-offset;
  border-radius: 0.35rem;
  box-sizing: border-box;
  @include box-shadow(0, 4px, 32px, -10px);
  @include mid-fluid-value(margin-right, 4.5rem, 13.5rem);

  & > div {
    &:not(:last-of-type) {
      margin-bottom: $card-item-offset;
    }
  }

  svg {
    &.icon {
      height: $default-icon-size;
      width: $default-icon-size;
      margin-right: $card-offset * 0.75;
    }
  }

  .title {
    font-size: 1.875rem;
    font-weight: 700;
    color: darken($text-color, 10);
  }

  .event {
    &_title,
    &_time_left {
      font-size: 1rem;
    }

    &_title {
      font-weight: 500;
      color: $grey-text;
      margin-bottom: $card-item-offset * 0.25;
    }

    &_time_left {
      position: relative;
      font-weight: 700;
      font-size: 1.375rem;
      text-transform: lowercase;
      color: $accent;

      &:hover {
        .event_link_arrow {
          right: 0;
        }
      }
    }

    &_link_arrow {
      right: 0.75rem;
      width: 1.5rem;
      height: 1.5rem;
      @include transition();
      @include centerAbsolutely(vertical);
    }
  }
}
