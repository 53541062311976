@import '../styles/variables.scss';
@import '../styles/theme-variables.scss';
@import '../styles/mixins.scss';

.auction_page {
  span {
    &:not([class]) {
      color: rgba($selected-text, 0.9);
      @include quickTransition();
      @include mid-fluid-value(font-size, 0.75rem, 0.8rem);
    }
  }

  svg {
    color: $selected-text;
  }

  .content_column {
    & > div {
      &:not(:last-of-type) {
        @include mid-fluid-value(margin-bottom, 1.25rem, 1.5rem);
      }
    }
  }

  .label {
    &_bold {
      @include quickTransition();
    }

    &_red {
      color: mix($error, $selected-text, 70);
    }

    &_grey {
      color: rgba($selected-text, 0.65);
      font-weight: 500;
    }

    &_accent {
      display: inline;
      font-weight: 600;
    }

    &_header {
      @include quickTransition();
      @include mid-fluid-value(font-size, 1.25rem, 1.5rem);

      &_small {
        margin-top: 0.5rem;
        font-size: 0.9rem;
        @include mid-fluid-value(margin-top, 0.15rem, 0.5rem);
      }
    }
  }

  .horizontal_spacing {
    & > * {
      &:not(:last-child) {
        margin-right: 1.75rem;
      }
    }
  }

  i {
    font-style: normal;
    font-size: 0.65em;
    line-height: inherit;
    color: rgba($selected-text, 0.65);
    font-weight: 600;
  }
}
