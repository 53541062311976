@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

.section_title {
  margin-top: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.125rem solid rgba($text-color, 0.5);

  svg {
    &.icon {
      width: 2rem;
      height: 2rem;
      margin-right: 0.75rem;
    }
  }

  .title {
    font-size: 1.75rem;
    font-weight: 600;
    color: $accent;
  }
}
