@import '../../styles/theme-variables.scss';

.description_row {
  & > div {
    color: $accent;
    font-weight: 600;
    text-align: center;
  }
}

.notification_container {
  min-width: 950px;
}
