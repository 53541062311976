@import './theme-variables.scss';

$font-size-xlarge: 40px;
$font-size-large: 30px;
$font-size-medium: 24px;
$font-size-small: 20px;
$font-size-xsmall: 16px;
$font-size-xxsmall: 14px;

$default-transition-time: 350ms;
$quick-transition-time: 150ms;

$header-height: 74px;

$spinner-size: 50px;

$light-color: #fff;
$dark-color: #232323;
$black-color: #000;
$grey-text: lighten($text-color, 40);
$custom-green: darken(adjust-hue($success, 50deg), 15);

$default-offset: 0.75rem;

$big-scale-size: 2.5;
$small-scale-size: 1 / 3;

$default-font: 'Montserrat', sans-serif;

$section-icon-size: 3rem;

$switch-box-shadow: inset 0 0 0 1px $accent;

$accent-light: rgba($accent, 0.9);

$form-input-offset-left: 1.25rem;

$default-card-width: 760px;

$max-side-bar-width: 13.75rem;

$side-menu-max-closed-width: 60px;
$side-menu-max-opened-width: 270px;

$form-section-font-size: 1.25rem;
$form-section-icon-font-size: $form-section-font-size * 1.75;
$form-section-icon-offset: 1rem;

$form-single-card-size: 43%;

$default-card-width: 760px;

$max-side-bar-width: 220px;

$content-wrapper-vertical-offset: 1.5rem;

$footer-height: 4rem;

$preview-layout-column-spacing: 2rem;

$menu-item-offset: 1.25rem;

$buttons-border-radius: 0.75rem;

// Breakpoint-y do media queries
$desktop-width: 1800px;
$big-laptop-screen: 1680px;
$medium-laptop-screen: 1440px;
$small-laptop-screen: 1360px;

// TODO Vorbert -> ujednolicić ze współdzielonym źródłem danych, znajdującym się w  hh-shared
