@import '../styles/variables.scss';
@import '../styles/theme-variables.scss';
@import '../styles/mixins.scss';

.transport_order_page {
  span {
    &:not([class]) {
      color: rgba($selected-text, 0.9);
      @include quickTransition();
      @include mid-fluid-value(font-size, 0.7rem, 0.8rem);
    }
  }

  .order_card {
    @include flex();
    height: 100%;
    max-height: 100vh;
    overflow: auto;
  }

  svg {
    color: $selected-text;

    &.form-icon {
      margin-top: 0.75rem;
    }
  }

  .content_column {
    & > div,
    .margin-bottom {
      &:not(:last-of-type) {
        @include mid-fluid-value(margin-bottom, 1.25rem, 1.5rem);
      }
    }
  }

  .content_row {
    & > div {
      &:not(:first-of-type) {
        margin-left: 5.5rem;
      }
    }
  }

  .adjusted-row {
    & > * {
      flex: 1;
    }
  }

  .triple-row {
    & > * {
      &:last-child,
      &:first-child {
        flex: 1;
      }

      &:nth-child(2) {
        flex: 0.45;
      }
    }
  }

  .flex-size {
    flex: 1;
  }

  .label {
    &_big {
      font-weight: 600;
      font-size: 2.1rem;
      line-height: 1.875rem;
      @include quickTransition();
      @include mid-fluid-value(font-size, 2rem, 2.1rem);
      @include mid-fluid-value(line-height, 1.7rem, 1.875rem);
    }

    &_accent {
      display: inline;
      font-weight: 600;
    }

    &_header {
      @include quickTransition();
      @include mid-fluid-value(font-size, 1.25rem, 1.5rem);

      &_small {
        margin-top: 0.5rem;
        font-size: 0.9rem;
        @include mid-fluid-value(margin-top, 0.15rem, 0.5rem);
      }
    }
  }
}
