@import '../styles/variables.scss';
@import '../styles/theme-variables.scss';
@import '../styles/mixins.scss';

.header {
  position: relative;
  min-width: 100%;
  min-height: $header-height;
  z-index: 2;
  background-color: transparent;

  @include flex(row, space-between, center);
  @include transition(min-height);

  .logo_container {
    justify-self: flex-start;
    margin-left: 1.25rem;
    @include flex(row, flex-start, center);
  }

  .logo {
    height: 3rem;
    @include transition(height);
  }

  .hamburger_menu {
    margin-right: 1.25rem;
    margin-bottom: 0.3125rem;
  }

  &_shrinked {
    min-height: 50px;

    .logo {
      height: 2rem;
    }
  }
}
