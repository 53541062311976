@import '../../styles/variables.scss';
@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

.header {
  background-color: $accent;
  color: white;
  display: flex;
  // TODO Vorbert -> wyekstraktować box-shadow do mixinsów/placeholderów
  box-shadow: 0 12px 8px -2px rgba(0, 0, 0, 0.25);

  @include mid-fluid-value(padding-top, 1.5rem, 2rem);
  @include mid-fluid-value(padding-right, 3rem, 4rem);
  @include mid-fluid-value(padding-bottom, 1.5rem, 2rem);
  @include mid-fluid-value(padding-left, 1rem, 1.5rem);

  span {
    display: block;
  }

  &_label {
    line-height: 100%;
    font-weight: 600;
    @include quickTransition(font-size);
    @include important-mid-fluid-value(font-size, 1.5rem, 2rem);

    &_medium {
      font-weight: 600;
      @include important-mid-fluid-value(font-size, 1rem, 1.5rem);
    }

    &_small {
      margin-top: 0.25rem;
      @include important-mid-fluid-value(font-size, 0.8rem, 1rem);
    }
  }
}

.progress_bar {
  margin-top: 0.25rem;
  width: 100%;
}

.time_section {
  margin-top: -0.75rem;
}

.status_label,
.time_section {
  @include mid-fluid-value(margin-left, 2.975rem, 5.5rem);
}

.canceled {
  &_row {
    align-items: center;
    margin-top: 0.25em;
  }

  &_time {
    margin-top: 0;
    margin-left: 0.75em;
    height: fit-content;
    align-self: center;
  }
}
