@import '../../styles/variables.scss';
@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/animations.scss';

.price_section_container {
  background-color: $accent;
  color: $selected-text;
  box-shadow: 0 -8px 8px -2px rgba(0, 0, 0, 0.25);

  @include mid-fluid-value(padding-top, 1.5rem, 2rem);
  @include mid-fluid-value(padding-right, 3rem, 4rem);
  @include mid-fluid-value(padding-bottom, 1.5rem, 2rem);
  @include mid-fluid-value(padding-left, 1rem, 1.5rem);

  @include small-laptop-fluid-value(padding-right, 1.5rem, 3rem);

  svg {
    color: $selected-text;
  }

  span {
    display: block;

    @include quickTransition();
    @include mid-fluid-value(font-size, 0.75rem, 0.8rem);
  }

  &__offer {
    display: flex;
    flex-direction: column;
  }

  button,
  .winning_label {
    padding: 0 2.5rem;
    height: 100%;
    border: none;
    border-radius: 0.125rem;
    color: $selected-text !important;
    background: $info !important;
    vertical-align: middle;
    font-size: 1.5rem;
    font-weight: 500;
    outline: none;
    user-select: none;
    box-sizing: border-box;

    @include quickTransition();
    @include mid-fluid-value(padding-left, 1.5rem, 2.5rem);
    @include mid-fluid-value(padding-right, 1.5rem, 2.5rem);
    @include mid-fluid-value(font-size, 1.25rem, 1.5rem);

    &:hover {
      cursor: pointer;
    }

    &:not(.winning_label) {
      @include mid-fluid-value(width, 11.375rem, 13.75rem);
    }

    &:active {
      &:not(.winning_label) {
        transform: scale(0.95);
      }

      &.winning_label {
        transform: scale(1.1);
      }
    }

    &:disabled {
      background: desaturate($info, 40) !important;
      color: $accent !important;

      &:hover {
        cursor: default;
      }
    }

    .orange {
      background: $warning !important;
    }
  }

  .winning_label {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: $success !important;
    border-radius: $buttons-border-radius;
    @include mid-fluid-value(padding-left, 0.75rem, 1rem);
    @include mid-fluid-value(padding-right, 0.75rem, 1rem);

    @include small-laptop-fluid-value(font-size, 1rem, 1.25rem);

    svg {
      width: 2rem;
      height: 2rem;

      @include mid-fluid-value(margin-left, 0.75rem, 1rem);
    }
  }
}

.label {
  font-weight: 600;
  color: $info;
  transition: all $quick-transition-time ease, color 900ms ease-in-out;
  @include mid-fluid-value(font-size, 1rem, 1.5rem);
  @include mid-fluid-value(margin-top, 0rem, 0.25rem);
}

.additional_margin {
  margin-bottom: 0.5rem;
}

$icon-size: 2rem;
$icon-responsive-sizing: 1.45rem;

.price_input {
  padding: 0.2rem 0.5rem;
  max-width: 12rem;
  min-width: 8rem;
  outline: none;
  border: none;
  border-radius: 0.125rem;
  color: $accent !important;
  font-weight: 500;
  font-size: 1.25rem;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}

.input_controls {
  position: relative;
  @include quickTransition(width);
  @include mid-fluid-value(width, 3.5rem, 5rem);
  @include small-laptop-fluid-value(width, 2.5rem, 3.5rem);
}

.icon {
  position: absolute;

  @include quickTransition();
  @include important-mid-fluid-value(
    width,
    $icon-responsive-sizing,
    $icon-size
  );
  @include important-mid-fluid-value(
    height,
    $icon-responsive-sizing,
    $icon-size
  );
  @include centerAbsolutely(horizontal);

  &:hover {
    cursor: pointer;
  }

  &_up {
    @include mid-fluid-value(top, $icon-size * 0.25, $icon-size * -0.1);
  }

  &_down {
    @include mid-fluid-value(bottom, $icon-size * -0.25, $icon-size * -0.5);
  }

  &:active {
    width: $icon-size * 0.9 !important;
    height: $icon-size * 0.9 !important;
  }

  &_disabled {
    pointer-events: none;
    filter: opacity(0.25);
  }
}

div.price_input_row {
  height: fit-content;
  width: auto;
}
